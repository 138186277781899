import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';

import css from './reservation.module.scss';

export const Reservation = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Reservation | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="Make here a reservation to visit the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2" isReservation>
                    <Title
                        as="h1"
                        className={css.title}
                        text="Reservation system"
                    />
                    <iframe
                        title="reservation-system"
                        frameBorder="0"
                        className={css.iframe}
                        src="https://widget.onlineafspraken.nl/consumer/booking/book/key/hcce71vntm86-zbze00/at/239479/rs/0/st/0/ln/1/l/11111/t/9fdc/f/110f0111/c/00aeef,6ccff7,000,00aeef,0076a4,fff,30,000/s"
                    />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
