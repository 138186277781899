import * as React from 'react';
import css from './google-maps.module.scss';

export const GoogleMaps = () => {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d608.9095171284836!2d4.894986068372228!3d52.37698244680669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c7f96bb5f1%3A0x85968732653057b9!2sSauna%20Nieuwezijds!5e0!3m2!1snl!2snl!4v1689007497182!5m2!1snl!2snl"
            width="600"
            height="450"
            className={css.root}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
        />
    );
};
