import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../common/elements/external-link/external-link';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';

import css from './privacy-statement.module.scss';

export const PrivacyStatement = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Privacyverklaring | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="The Privacy statement of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <ContentBlock className={css.text} position="1">
                    <Title as="h1" text="privacyverklaring" />
                    <Paragraph>
                        Dit is de privacyverklaring van Sauna Nieuwezijds,
                        gevestigd te Amsterdam, ingeschreven in het
                        handelsregister van de Kamer van Koophandel onder nummer
                        57201714, hierna te noemen: `de Sauna`.
                    </Paragraph>
                    <Paragraph>
                        Bewust of onbewust deelt u persoonsgegevens met ons. Wij
                        vinden het van belang dat zorgvuldig wordt omgegaan met
                        uw persoonsgegevens.
                        <br />
                        Daarom hebben wij in dit statement voor u uiteengezet
                        hoe wij met uw persoonsgegevens omgaan en welke rechten
                        u heeft.
                        <br />
                        Heeft u vragen naar aanleiding van het lezen van dit
                        statement, stel uw vraag dan liefst per e-mail:{' '}
                        <ExternalLink
                            href="mailto:manager@saunanieuwezijds.nl"
                            text="manager@saunanieuwezijds.nl"
                        />
                    </Paragraph>
                    <Paragraph>
                        Onderstaand ziet u met welk doel we welke
                        persoonsgegevens van u verzamelen, hoelang wij deze
                        bewaren en wie deze persoonsgegevens eventueel van ons
                        ontvangen.
                    </Paragraph>
                    <dl className={css.list}>
                        <dt>Doel:</dt>
                        <dd>Reservatie-systeem</dd>

                        <dt>Welke (persoons) gegevens:</dt>
                        <dd>Naam of koosnaam, telefoonnummer, e-mail adres</dd>

                        <dt>Grondslag:</dt>
                        <dd>GGD Amsterdam</dd>

                        <dt>Bewaartermijn:</dt>
                        <dd>3 weken voor mogelijk contact tracering</dd>

                        <dt>Ontvangers:</dt>
                        <dd>Directie & Management</dd>
                    </dl>
                    <Title
                        underlined={false}
                        className={css.subHeader}
                        as="h2"
                        isBold
                        noMargin
                        text="Verstrekking van persoonsgegevens aan derden"
                    />
                    <Paragraph>
                        Zonder uw toestemming verstrekt de Sauna uw
                        persoonsgegevens alleen als de Sauna wettelijk verplicht
                        is om uw persoonsgegevens te verstrekken aan deze
                        derden.
                    </Paragraph>
                </ContentBlock>
                <ContentBlock position="2">
                    <Title
                        as="h2"
                        isBold
                        underlined={false}
                        text="Beveiliging persoonsgegevens"
                        className={css.subHeader}
                        noMargin
                    />
                    <Paragraph>
                        De Sauna treft passende maatregelen om uw
                        persoonsgegevens te beveiligen tegen verlies,
                        ongeautoriseerde toegang, publicatie en onrechtmatige
                        verwerking.
                        <br />
                        Zo zorgen wij dat alleen de noodzakelijke personen
                        toegang hebben tot uw gegevens, dat de toegang tot de
                        persoonsgegevens afgeschermd is en dat onze
                        veiligheidsmaatregelen regelmatig gecontroleerd worden.
                    </Paragraph>

                    <Title
                        as="h2"
                        isBold
                        noMargin
                        underlined={false}
                        text="Contactgegevens"
                        className={css.subHeader}
                    />

                    <Paragraph>
                        Indien u vragen hebt over de verwerking van uw
                        persoonsgegevens of de Sauna wilt verzoeken tot inzage,
                        correctie en/of verwijdering van uw persoonsgegevens,
                        kunt u contact opnemen via onderstaande:
                        <br />
                        Lorenzo Borella,{' '}
                        <ExternalLink
                            text="manager@saunanieuwezijds.nl"
                            href="mailto:manager@saunanieuwezijds.nl"
                        />
                    </Paragraph>

                    <Title
                        as="h2"
                        isBold
                        noMargin
                        underlined={false}
                        text="Klacht over de verwerking van uw Persoonsgegevens"
                        className={css.subHeader}
                    />
                    <Paragraph>
                        Natuurlijk helpen wij u ook graag verder als u een
                        klacht heeft over de verwerking van uw persoonsgegevens.
                        Op grond van de AVG heeft u bovendien het recht om een
                        klacht in te dienen bij de Autoriteit Persoonsgegevens
                        over onze verwerkingen van uw persoonsgegevens.
                        <br />U kunt hiervoor contact opnemen met de Autoriteit
                        Persoonsgegevens.
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
