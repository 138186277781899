import * as React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../../common/elements/external-link/external-link';

import css from '../massage.module.scss';

import haraldImg from '../../../common/assets/masseur-harald-big.jpg';

export const Harald = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Masseur Harald | Massage | Nieuwezijds Gay sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about Robert, one of the masseurs available in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock showPicture imageSrc={haraldImg} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/massage" text="massage" />
                    )}
                    <Title as="h1" text="Harald" />
                    <Paragraph>
                        Harald is a certified massage therapist whose goal is to
                        provide you with a high-quality full body wellness
                        experience that will put your body and mind in a deep
                        state of relaxation.
                    </Paragraph>

                    <Paragraph>
                        His style is a fusion of deep tissue, sport and
                        therapeutic massage.
                        <br />
                        Various stress-relieving techniques will be applied such
                        as intensive kneading, friction and deep strokes.
                    </Paragraph>

                    <Paragraph>
                        The massage will be personalized to your wishes and
                        needs.
                        <br />
                        Communicate freely both before and during your treatment
                        about pressure and which areas need focus or avoidance.
                    </Paragraph>
                    <Paragraph>
                        book a session at the bar or call in advance to make a
                        reservation:
                        <br />
                        <ExternalLink
                            className={css.big}
                            href="tel:31203318327"
                            text="+31 20 331 8327"
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
