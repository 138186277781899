import * as React from 'react';
import { Title } from '../../elements/title/title';

import css from './opening-hours.module.scss';

export const OpeningHours = () => {
    return (
        <>
            <Title as="h2" text="opening hours" />
            <table className={css.root}>
                <tr>
                    <td>Weekdays and Sundays:</td>
                    <td>12:00 (noon) till 02:00 (2am)</td>
                </tr>
                <tr>
                    <td>Fridays and Saturdays:</td>
                    <td>12:00 (noon) till 10:00 (10am)</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <strong>pride opening hours:</strong>
                    </td>
                </tr>
                <tr>
                    <td>Thursday 1 august:</td>
                    <td>12:00 (noon) till 04:00 (4am)</td>
                </tr>
                <tr>
                    <td>Sunday 4 august:</td>
                    <td>12:00 (noon) till 06:00 (6am)</td>
                </tr>
            </table>
        </>
    );
};
