import * as React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';

import css from '../agenda-item.module.scss';

import noTowelTuesday from '../../../common/assets/no-towel-tuesday.jpg';

export const NoTowelTuesday = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    No towel Tuesday | Agenda | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about Now Towel Tuesday, one of the events in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock imageSrc={noTowelTuesday} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/agenda" text="agenda" />
                    )}
                    <Title as="h1" text="No Towel Tuesday" noMargin />
                    <Title
                        as="h2"
                        text="get totally naked!"
                        underlined={false}
                    />
                    <Paragraph>
                        every tuesday between 18:00 & 02:00
                        <br />
                        (we are open from 12:00 till 02:00)
                    </Paragraph>

                    <Paragraph>
                        you get a small towel to sit on and you can ask for a
                        regular towel to dry off with.
                    </Paragraph>
                    {/*  <Paragraph>
                        towels allowed again from midnight onwards.
                    </Paragraph>*/}
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
