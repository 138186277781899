import * as React from 'react';

import css from './picture-link.module.scss';

interface PictureLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    href: string;
    className?: string;
    text: string;
    image: string;
}

export const PictureLink: React.FC<PictureLinkProps> = ({
    href,
    className,
    text,
    image,
}) => {
    return (
        <a
            href={href}
            className={`${className} ${css.root}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img className={css.logo} src={image} alt={text} />
            <br />
            {text}
        </a>
    );
};
