import * as React from 'react';
import { Link } from 'react-router-dom';

import css from './agenda-list-item.module.scss';

interface AgendaListItemProps {
    eventName: string;
    eventInfo: string;
    eventLink: string;
    eventExtra?: string;
}

export const AgendaListItem: React.FC<AgendaListItemProps> = ({
    eventName,
    eventInfo,
    eventLink,
    eventExtra = false,
}) => {
    return (
        <li className={css.root}>
            <Link className={css.link} to={eventLink}>
                {eventName}
            </Link>{' '}
            {eventInfo}
            {eventExtra && (
                <>
                    <br />
                    {eventExtra}
                </>
            )}
        </li>
    );
};
