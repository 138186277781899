import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { GalleryPics } from '../../common/features/carousel/carousel';

import css from './gallery.module.scss';

export const Gallery = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Gallery | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="Picture gallery of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <ContentBlock isFullColor noPadding position="full">
                    <GalleryPics />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
