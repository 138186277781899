import * as React from 'react';
import { Helmet } from 'react-helmet';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Paragraph } from '../../common/elements/paragraph/paragraph';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { Title } from '../../common/elements/title/title';

import css from './error.module.scss';

export const Error = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Nieuwezijds Gay Sauna Amsterdam</title>
            </Helmet>
            <section className={css.root}>
                <PictureBlock isHome />
                <ContentBlock isHome position="2">
                    <Title as="h1" text="Something went wrong" />
                    <Paragraph>Use the menu to navigate.</Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
