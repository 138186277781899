import * as React from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';

import css from './content-block.module.scss';

interface ContentBlockProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    position: '1' | '2' | 'full';
    noPadding?: boolean;
    isHome?: boolean;
    isReservation?: boolean;
    isFullColor?: boolean;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
    children,
    position,
    noPadding = false,
    isHome = false,
    isReservation = false,
    isFullColor = false,
}) => {
    const classes = classNames(
        css.root,
        position === '1' && css.first,
        position === '2' && css.second,
        position === 'full' && css.full,
        noPadding && css.noPadding,
        isReservation && css.isReservation,
        isFullColor && css.isFullColor,
        isHome && (isMobileOnly || isPortraitTablet) && css.isHome,
    );
    return <article className={classes}>{children}</article>;
};
