import * as React from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';

import css from './masseur-list-item.module.scss';

interface MassageListItemProps {
    masseurName: string;
    masseurExraInfo?: string;
    masseurLink: string;
    masseurPic: string;
}

export const MasseurListItem: React.FC<MassageListItemProps> = ({
    masseurName,
    masseurExraInfo = false,
    masseurLink,
    masseurPic,
}) => {
    return (
        <Link className={css.root} to={masseurLink}>
            <div className={css.content}>
                <Title
                    noMargin
                    className={css.title}
                    underlined={false}
                    as="h2"
                    text={masseurName}
                />
                {masseurExraInfo && (
                    <Paragraph className={css.text}>
                        {masseurExraInfo}
                    </Paragraph>
                )}
            </div>

            <figure className={css.pic}>
                <img className={css.image} src={masseurPic} alt={masseurName} />
            </figure>
        </Link>
    );
};
