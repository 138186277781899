import * as React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { BackLink } from '../../../common/elements/back-link/back-link';

import css from '../agenda-item.module.scss';

import mellowMondayImg from '../../../common/assets/mellow-monday.jpg';

export const MellowMonday = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Mellow Monday | Agenda | Nieuwezijds Gay Sauna Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about mellow Mondays, one of the events in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock imageSrc={mellowMondayImg} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/agenda" text="agenda" />
                    )}
                    <Title as="h1" text="Mellow Monday" noMargin />
                    <Title
                        as="h2"
                        text="recharge after your weekend"
                        underlined={false}
                    />
                    <Paragraph>
                        every monday between 19:00 & 22:00
                        <br />
                        (we are open from 12:00 till 02:00)
                    </Paragraph>
                    <ul className={css.list}>
                        <li>free shoulder massages from 5-7pm</li>
                        <li>
                            aufg&uuml;ss/infusion ceremony (dry sauna) at 7pm &
                            9pm
                        </li>
                        <li>chocolate session (steam sauna) at 8pm</li>

                        <li>free (healthy) snacks all night</li>
                    </ul>
                    <Paragraph>
                        guys 26yrs and younger for only &euro;10!
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
