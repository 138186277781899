import * as React from 'react';
import { Title } from '../../elements/title/title';
import { Paragraph } from '../../elements/paragraph/paragraph';
import { ExternalLink } from '../../elements/external-link/external-link';

export const Parking = () => {
    return (
        <>
            <Title as="h2" text="parking" />
            <Paragraph>
                <ExternalLink
                    href="https://www.q-park.nl/nl-nl/parkeren/amsterdam/nieuwendijk/"
                    text="q-park nieuwendijk"
                />
                <br />
                nieuwezijds kolk 18
                <br />
                1012 pv amsterdam
            </Paragraph>
        </>
    );
};
