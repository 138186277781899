import * as React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Routes from './routes';
import { isTablet } from 'react-device-detect';
import { SideBar } from './common/features/side-bar/side-bar';

import css from './app.module.scss';

export const isPortraitTablet =
    window.matchMedia('(orientation: portrait)').matches && isTablet;

function App() {
    const history = createBrowserHistory();

    ReactGA.initialize('UA-90562650-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    return (
        <Suspense fallback="loading">
            <BrowserRouter>
                <Router history={history}>
                    <main className={css.root}>
                        <SideBar />
                        <section className={css.content}>
                            <Routes />
                        </section>
                    </main>
                </Router>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
