import * as React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { ExternalLink } from '../../../common/elements/external-link/external-link';

import css from './../info.module.scss';

export const SexualHealth = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Sexual Health | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="The sexual health policy of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/info" text="info" />
                    )}
                    <Title as="h1" text="Sexual Health" />

                    <Paragraph>
                        we want our sauna to be a safe place for everyone. keep
                        it safe & sane - know your hiv status, protect yourself
                        & others with condoms, prep or hiv medication. we
                        strongly recommend you to use condoms to prevent most
                        sti's. condoms and lube ("wet stuff", water-based) are
                        available free of charge
                    </Paragraph>
                    <Paragraph>
                        you can find more health info here:
                        <br />
                        <ExternalLink
                            href="https://www.mantotman.nl/en"
                            text="general sexual health, dutch & english"
                        />
                        <br />
                        <ExternalLink
                            href="https://www.mantotman.nl/en/stds-hiv"
                            text="sti's, hiv & prep"
                        />
                        <br />
                        <ExternalLink
                            href="https://nomorec.nl/"
                            text="everything about hepatitis c"
                        />
                        <br />
                        <ExternalLink
                            href="https://www.mantotman.nl/nl/snel-regelen/vaccinaties-tegen-hepatitis"
                            text="hepatitis a & b vaccination"
                        />
                        <br />
                        <ExternalLink
                            href="https://www.mantotman.nl/en/stds-hiv/all-stds/monkeypox"
                            text="monkey pox update"
                        />
                    </Paragraph>
                    <Paragraph>
                        need to get an urgent sti test and don't mind paying?
                        visit{' '}
                        <ExternalLink
                            href="https://onedayclinic.nl/en/?gclid=EAIaIQobChMI4Pu8haSoggMVRp4AAB3gsgZjEAEYASAAEgJGlvD_BwE"
                            text="onedayclinic.nl"
                        />
                    </Paragraph>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
