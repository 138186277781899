import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';
import { ExternalLink } from '../../common/elements/external-link/external-link';

import css from './links.module.scss';

export const Links = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Links | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="Website links to the friends of the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    <section className={css.container}>
                        <div className={css.listBlock}>
                            <Title text="gay businesses" as="h1" noMargin />
                            <Title text="& groups" as="h2" underlined={false} />
                            <ul className={css.list}>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="http://www.clubchurch.nl"
                                        text="club church"
                                    />
                                </li>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="https://www.freewillie.nl/"
                                        text="free willie"
                                    />
                                </li>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="http://www.xxxleather.nl/"
                                        text="xxxleather"
                                    />
                                </li>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="http://www.furball.nl/"
                                        text="furball"
                                    />
                                </li>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="http://www.ladz.eu"
                                        text="ladz"
                                    />
                                </li>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="https://www.pinkpoint.nl/"
                                        text="pink point"
                                    />
                                </li>
                                <li className={css.item}>
                                    <ExternalLink
                                        href="https://www.gayamsterdam.com/"
                                        text="gay amsterdam"
                                    />
                                </li>
                            </ul>
                        </div>
                    </section>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
