import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '../../common/scripts/scroll-to-top';
import { ContentBlock } from '../../common/elements/content-block/content-block';
import { Title } from '../../common/elements/title/title';
import { MassageText } from '../../common/content/massage-text/massage-text';
import { MasseurListItem } from '../../common/features/masseur-list/masseur-list-item';
import { PictureBlock } from '../../common/elements/picture-block/picture-block';

import css from './massage.module.scss';

import richard from './../../common/assets/masseur-richard-small.jpg';
import harald from './../../common/assets/masseur-harald-small.jpg';

export const Massage = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>Massage | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="Information about the massages you can have in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    <Title as="h1" text="massage" />
                    <MasseurListItem
                        masseurName="Richard Hidalgo"
                        masseurLink="/massage/richard"
                        masseurExraInfo="Rich Sport Massage"
                        masseurPic={richard}
                    />
                    <MasseurListItem
                        masseurName="Harald"
                        masseurLink="/massage/harald"
                        masseurExraInfo="Certified massage therapist"
                        masseurPic={harald}
                    />
                    <MassageText />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
