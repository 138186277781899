import * as React from 'react';
import classNames from 'classnames';

import css from './paragraph.module.scss';

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
    children: React.ReactNode;
    promo?: boolean;
    isHome?: boolean;
    noMargin?: boolean;
    className?: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({
    children,
    promo = false,
    isHome = false,
    noMargin = false,
    className,
}) => {
    const classes = classNames(
        className,
        css.root,
        isHome && css.isHome,
        promo && css.promo,
        noMargin && css.noMargin,
    );
    return <p className={classes}>{children}</p>;
};
