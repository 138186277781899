import * as React from 'react';
import { Helmet } from 'react-helmet';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { PictureLink } from '../../../common/elements/picture-link/picture-link';

import css from './nz-supports.module.scss';

import aidsMomumentAmsterdam from '../../../common/assets/logos/logo-aidsmonument-amsterdam.gif';
import rozeFilmDagen from '../../../common/assets/logos/logo-roze-filmdagen.png';
import homoMonument from '../../../common/assets/logos/logo-homomonument.png';
import documentOurHistory from '../../../common/assets/logos/logo-documentourhistory.gif';
import amsterdamTigers from '../../../common/assets/logos/logo-amsterdam-tigers.png';
import upstream from '../../../common/assets/logos/logo-upstream.gif';
import gaySwimAmsterdam from '../../../common/assets/logos/logo-gayswim-amsterdam.png';
import plons from '../../../common/assets/logos/logo-plons.gif';
import streetHeart from '../../../common/assets/logos/logo-streetheart.png';
import tranScreen from '../../../common/assets/logos/logo-transcreen.png';
import seniorPride from '../../../common/assets/logos/logo-senior-pride.png';
import prepNu from '../../../common/assets/logos/logo-prepnu.png';
import houseOfHiv from '../../../common/assets/logos/logo-houseofhiv.jpg';
import loveSwim from '../../../common/assets/logos/logo-loveswim.gif';

export const NzSupports = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>NZ Supports | Nieuwezijds Gay Sauna Amsterdam</title>
                <meta
                    name="description"
                    content="Projects which are supported by the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/info" text="info" />
                    )}
                    <Title as="h1" text="NZ Supports" />
                    <Paragraph>
                        as a proud member of the LGBTQIA+ community, sauna nz
                        supports the following projects in amsterdam:
                    </Paragraph>
                    <article className={css.links}>
                        <PictureLink
                            href="http://www.aidsmonument-amsterdam.nl/"
                            text="aids monument amsterdam"
                            image={aidsMomumentAmsterdam}
                            className={css.block}
                        />
                        <PictureLink
                            href="http://www.rozefilmdagen.nl/"
                            text="de roze film dagen (amsterdam lgbtq film festival)"
                            image={rozeFilmDagen}
                            className={css.block}
                        />
                        <PictureLink
                            href="http://homomonument.nl/"
                            text="het homomonument"
                            image={homoMonument}
                            className={css.block}
                        />
                        <PictureLink
                            href="http://documentourhistorynow.org/"
                            text="document our history"
                            image={documentOurHistory}
                            className={css.block}
                        />
                        <PictureLink
                            href="http://www.amsterdam-tigers.nl/"
                            text="amsterdam tigers"
                            image={amsterdamTigers}
                            className={css.block}
                        />
                        <PictureLink
                            href="http://www.upstreamamsterdam.nl/"
                            text="upstream amsterdam"
                            image={upstream}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://www.gayswimamsterdam.nl/"
                            text="gayswim amsterdam"
                            image={gaySwimAmsterdam}
                            className={css.block}
                        />
                        <PictureLink
                            href="http://www.plons.nu/"
                            text="plons"
                            image={plons}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://www.facebook.com/StreetHeartAmsterdam/"
                            text="streetheart"
                            image={streetHeart}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://transcreen.eu/"
                            text="transcreen"
                            image={tranScreen}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://www.seniorpride.amsterdam/over-ons/"
                            text="senior pride"
                            image={seniorPride}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://www.prepnu.nl/"
                            text="prep nu"
                            image={prepNu}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://www.hellogorgeous.nl/"
                            text="house of hiv"
                            image={houseOfHiv}
                            className={css.block}
                        />
                        <PictureLink
                            href="https://loveswim.nl/"
                            text="love swim"
                            image={loveSwim}
                            className={css.block}
                        />
                    </article>
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
