import * as React from 'react';
import { Link } from 'react-router-dom';

import css from './home-agenda.module.scss';

export const HomeAgenda = () => {
    return (
        <article className={css.root}>
            <ol className={css.list}>
                <li className={css.listItem}>
                    <Link className={css.link} to="/agenda/mellow-monday">
                        Mellow Monday
                    </Link>
                    <span className={css.text}>every monday 19:00 - 22:00</span>
                </li>
                <li className={css.listItem}>
                    <Link className={css.link} to="/agenda/no-towel-tuesday">
                        No Towel Tuesday
                    </Link>
                    <span className={css.text}>
                        every tuesday 18:00 - 02:00
                    </span>
                </li>
                <li className={css.listItem}>
                    <Link className={css.link} to="/agenda/queer-night">
                        Queer Night
                    </Link>
                    <span className={css.text}>every wednesday</span>
                </li>
                <li className={css.listItem}>
                    <Link className={css.link} to="/agenda/the-night-is-young">
                        The Night Is Young
                    </Link>
                    <span className={css.text}>every thursday</span>
                </li>
                <li className={css.listItem}>
                    <Link className={css.link} to="/agenda/bears-at-nz">
                        Bears (working up a sweat)
                    </Link>
                    <span className={css.text}>every last saturday</span>
                </li>
                <li>
                    Check our{' '}
                    <Link className={css.link} to="/agenda">
                        agenda
                    </Link>{' '}
                    for more information
                </li>
            </ol>
        </article>
    );
};
