import * as React from 'react';
import { Link } from 'react-router-dom';
import { Paragraph } from '../paragraph/paragraph';

import css from './back-link.module.scss';

interface BackLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    href: string;
    text: string;
}

export const BackLink: React.FC<BackLinkProps> = ({ href, text }) => {
    return (
        <Paragraph>
            <Link className={css.root} to={href}>
                {text}
            </Link>
        </Paragraph>
    );
};
