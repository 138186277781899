import * as React from 'react';
import { Paragraph } from '../../elements/paragraph/paragraph';
import { ExternalLink } from '../../elements/external-link/external-link';

import css from './massage-text.module.scss';

export const MassageText = () => {
    return (
        <>
            <Paragraph noMargin className={css.text}>
                our masseurs are available from 14:00 till around midnight. Call
                in advance or ask at the entrance or bar to see who is available
                and book a massage:
            </Paragraph>
            <Paragraph noMargin>
                <span className={css.big}>
                    <ExternalLink
                        href="tel:31203318327"
                        text="+31 20 331 8327"
                    />
                </span>
            </Paragraph>
            <Paragraph>
                massage clients (60 or 90 min) get € 8 discount to the sauna.
                our masseurs offer you a professional massage, not a sexual
                service. erotic advances are not appreciated.
            </Paragraph>
        </>
    );
};
