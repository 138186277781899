import * as React from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from '../navigation/navigation';

import css from './side-bar.module.scss';

import logo from './../../assets/logo-sauna.png';
import googleMaps from '../../assets/home-info-link.gif';

export const SideBar = () => {
    return (
        <section className={css.root}>
            <figure className={css.logo}>
                <Link to="/">
                    <img src={logo} alt="Nieuwezijds Gay Sauna Amsterdam" />
                </Link>
            </figure>
            <Navigation />
            <figure className={css.googleMaps}>
                <Link to="/info">
                    <img
                        src={googleMaps}
                        alt="2 minutes from central station"
                    />
                </Link>
            </figure>
        </section>
    );
};
