import * as React from 'react';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { isPortraitTablet } from '../../../app';
import { BackLink } from '../../../common/elements/back-link/back-link';
import { PictureBlock } from '../../../common/elements/picture-block/picture-block';
import { ContentBlock } from '../../../common/elements/content-block/content-block';
import { Title } from '../../../common/elements/title/title';
import { ScrollToTop } from '../../../common/scripts/scroll-to-top';
import { Paragraph } from '../../../common/elements/paragraph/paragraph';
import { LastSaturdays } from './last-saturdays';

import css from '../agenda-item.module.scss';

import bearsAtNz from '../../../common/assets/bears-at-nz.jpg';

export const BearsAtNz = () => {
    return (
        <ScrollToTop>
            <Helmet>
                <title>
                    Bears working up a sweat | Agenda | Nieuwezijds Gay Sauna
                    Amsterdam
                </title>
                <meta
                    name="description"
                    content="Information about Bears, working up a sweat, one of the events in the Nieuwezijds Gay Sauna in Amsterdam."
                />
            </Helmet>
            <section className={css.root}>
                <PictureBlock imageSrc={bearsAtNz} />
                <ContentBlock position="2">
                    {(isMobileOnly || isPortraitTablet) && (
                        <BackLink href="/agenda" text="agenda" />
                    )}
                    <Title as="h1" text="bears at nz" noMargin />
                    <Title
                        as="h2"
                        text="working up a sweat"
                        underlined={false}
                    />
                    <Paragraph>every last saturday</Paragraph>
                    <Paragraph>
                        the bears, daddies, chasers, chubs and their admirers
                        meet at NZ
                    </Paragraph>

                    <ul className={css.list}>
                        <li>free healthy snacks</li>
                        <li>free soup late afternoon</li>
                        <li>
                            aufgüss/infusion ceremony (dry sauna) at 5pm & 7pm
                        </li>
                    </ul>

                    <LastSaturdays />
                </ContentBlock>
            </section>
        </ScrollToTop>
    );
};
